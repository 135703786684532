import logo from './images/avatar.jpg';
import twitter from './images/twitter-logo.png';
import './App.css';

function App() {
  return (
    <div className="Main">
      <header className="Main-header">
        <a href="https://twitter.com/Thorlon">
          <img src={logo} className="Main-logo" alt="logo" />
        </a>
        <div className="padding">
          <a href="https://twitter.com/Thorlon">
            <img alt="twitter" src={twitter} width="70" height="70" /></a>
        </div>
      </header>
    </div>


  );
}

export default App;
